class api {
  constructor (http) {
    this._http = http
    this.client = '/api/'
  }

  loadItem (id = null) {
    var url = this.client + 'getSingleLoreItem/?id=' + id

    return this.request(url)
  }

  loadKeys () {
    var url = this.client + 'getShuffledKeys/'

    return this.request(url)
  }

  request (url) {
    return new Promise((resolve) =>
      this._http.get(url).then(response => {
        response.json().then(json => {
          resolve(json)
        })
      }, response => {
        console.warn(response)
        resolve([])
      })
    )
  }
}

export default api

<template>
  <section class="generator">
    <div v-if="!isOpened" class="generator__engram-wrapper">
      <div class="generator__shift generator__shift--inner"></div>
      <div class="generator__shift generator__shift--squares"></div>
      <div class="generator__shift generator__shift--outer"></div>
      <div class="generator__engram" @click="getItem">
        <div class="generator__engram-bottom"></div>
        <div class="generator__engram-left"></div>
        <div class="generator__engram-right"></div>
      </div>
      <p class="generator__engram-title">Нажмите на энграмму, чтобы получить историю</p>
    </div>
    <transition name="roll">
      <article v-if="isOpened" :class="['generator__card', { active: isOpened, error: isError }]">
        <!-- <div class="generator__card-close" @click="closeItem"></div> -->
        <button class="generator__card-button" @click="getItem(event, true)">
          <div class="generator__card-button-inner">
            <div class="generator__engram generator__engram--inner">
              <div class="generator__engram-bottom"></div>
              <div class="generator__engram-left"></div>
              <div class="generator__engram-right"></div>
            </div>
          </div>
        </button>
        <div class="generator__card-content">
          <h1 class="generator__card-title">{{ this.selectedItem.displayProperties.name }}</h1>
          <p v-if="selectedItem.subtitle" class="generator__card-subtitle">{{ this.selectedItem.subtitle }}</p>
          <!-- <div v-if="selectedItem.displayProperties.icon" class="generator__card-image-wrapper">
            <img :src="image" :alt="selectedItem.displayProperties.name">
          </div> -->
          <div class="generator__card-text">
              {{ this.selectedItem.displayProperties.description }}
          </div>
        </div>
      </article>
    </transition>
  </section>
</template>

<script>
export default {
  name: 'Generator',
  data () {
    return {
      keys: [],
      itemId: Number,
      selectedItem: Object,
      isOpened: false,
      isError: false,
      timeout: 2000
    }
  },
  computed: {
    image: function () {
      var image = 'https://www.bungie.net/' + this.selectedItem.displayProperties.icon
      console.log(image)
      return image
    }
  },
  methods: {
    getItem (event, insideCard = false) {
      var key = this.keys.shift()
      this.itemId = key
      this.makeRequest(insideCard)
    },
    makeRequest (insideCard) {
      var wrapperClassName = 'generator__engram-wrapper'
      var wrapper = document.querySelector('.' + wrapperClassName)
      var engramClassName = 'generator__engram'
      var engramOuter = document.querySelector('.' + engramClassName)
      var engramInner = document.querySelector('.' + engramClassName + '--inner')
      var that = this
      var engram = ''

      if (insideCard === false) {
        engram = engramOuter
      } else {
        engram = engramInner
      }

      engram.classList.add(engramClassName + '--loading')

      setTimeout(function () {
        that.$api.loadItem(that.itemId).then(data => {
          if (Object.keys(data).length > 0) {
            if (!data.displayProperties.description) {
              that.getItem(insideCard)
            } else {
              that.selectedItem = data
              engram.classList.remove(engramClassName + '--loading')
              wrapper.classList.add(wrapperClassName + '--loaded')
              setTimeout(function () {
                that.isOpened = true
              }, 1000)
            }
          } else {
            that.selectedItem = {
              displayProperties: {
                name: 'Манифест украли вексы',
                description: 'Высылаем боевую группу на поиски'
              }
            }
            that.isError = true
            that.isOpened = true
          }
        })
      }, this.timeout)
    },
    closeItem () {
      this.isOpened = false
      this.isError = false
      this.selectedItem = Object
    }
  },
  mounted () {
    this.$api.loadKeys().then(data => {
      this.keys = data
    })
  }
}
</script>

<style lang="scss">
  $className: 'generator';
  $xsmall: '400px';
  $small: '600px';
  $medium: '800px';

  @font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('~@/assets/fonts/HelveticaNeueCyr-Medium.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('~@/assets/fonts/HelveticaNeueCyr-Roman.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @-moz-keyframes spin {
    0% {
      -moz-transform: translateX(-50%) translateY(-50%) rotate(0);
    }
    100% {
      -moz-transform: translateX(-50%) translateY(-50%) rotate(var(--degrees));
    }
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: translateX(-50%) translateY(-50%) rotate(0deg) scale(1);
    }
    100% {
      -webkit-transform: translateX(-50%) translateY(-50%) rotate(var(--degrees)) scale(1);
    }
  }

  @keyframes spin {
    0% {
      transform: translateX(-50%) translateY(-50%) rotate(0);
    }
    100% {
      transform: translateX(-50%) translateY(-50%) rotate(var(--degrees));
    }
  }

  @keyframes shrink {
    0% {
      transform: translateX(-50%) translateY(-50%) scale(1);
    }
    100% {
      transform: translateX(-50%) translateY(-50%) scale(0.5);
    }
  }

  @keyframes bravo-6-going-dark {
    100% {
      opacity: 0;
    }
  }

  @keyframes fade-in-out {
    0% { opacity: 1; }
    50% { opacity: 0.6; }
    100% { opacity: 1; };
  }

  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "HelveticaNeueCyr", Arial, Helvetica, sans-serif;
    font-weight: normal;
  }

  .roll-enter-active, .roll-leave-active {
    transition: all 2s;
    max-height: 5000px;
  }

  .roll-enter, .roll-leave-to {
    max-height: 0;
  }

  .#{$className} {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: #262626;

    &__engram-wrapper {
      width: 500px;
      height: 560px;
      position: relative;
      overflow: hidden;

      @media (max-width: $small) {
        width: 400px;
        height: 460px;
      }

      @media (max-width: $xsmall) {
        width: 300px;
        height: 400px;
      }

      &--loaded {
        .#{$className} {
          &__engram {
            animation: shrink 1s normal forwards;
          }

          &__shift,
          &__engram-title {
            animation: bravo-6-going-dark 1s normal forwards;
          }
        }
      }
    }

    &__shift {
      width: 470px;
      height: 470px;
      pointer-events: none;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      backface-visibility: hidden;
      outline: 1px solid transparent;

      &--inner {
        --degrees: 360deg;
        background-image: url('~@/assets/inner.png');
        transform: translateX(-50%) translateY(-50%);
        -webkit-animation:spin 90s linear infinite;
          -moz-animation:spin 90s linear infinite;
             animation:spin 90s linear infinite;
      }

      &--squares {
        --degrees: -360deg;
        background-image: url('~@/assets/squares.png');
        transform: translateX(-50%) translateY(-50%);
        -webkit-animation:spin 75s linear infinite;
          -moz-animation:spin 75s linear infinite;
             animation:spin 75s linear infinite;
      }

      &--outer {
        --degrees: 360deg;
        background-image: url('~@/assets/outer.png');
        transform: translateX(-50%) translateY(-50%);
        -webkit-animation:spin 82s linear infinite;
          -moz-animation:spin 82s linear infinite;
            animation:spin 82s linear infinite;
      }

      @media (max-width: $small) {
        width: 370px;
        height: 370px;
      }

      @media (max-width: $xsmall) {
        width: 270px;
        height: 270px;
      }
    }

    &__engram-title {
      color: #fff;
      position: absolute;
      bottom: 0;
      text-align: center;
      width: 100%;
    }

    &__engram  {
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      position: absolute;
      cursor: pointer;
      font-size: 0;
      width: 200px;
      height: 200px;
      background: url('~@/assets/engram-carcass.png') no-repeat center;

      @media (max-width: $small) {
        width: 150px;
        height: 150px;
        background-size: cover;
      }

      @media (max-width: $xsmall) {
        width: 100px;
        height: 100px;
      }

      &--loading {
        .#{$className}__engram {
          &-bottom,
          &-left,
          &-right {
            animation: fade-in-out 2s ease-in-out infinite;
          }

          &-left {
            animation-delay: .5s;
          }

          &-right {
            animation-delay: 1s;
          }
        }
      }
    }

    &__engram-bottom,
    &__engram-left,
    &__engram-right {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &__engram-bottom {
      background-image: url('~@/assets/engram-bottom.png');
    }

     &__engram-left {
      background-image: url('~@/assets/engram-left.png');
    }

     &__engram-right {
      background-image: url('~@/assets/engram-right.png');
    }

    &__card {
      position: relative;
      max-width: 600px;
      width: 100%;
      height: 100%;
      overflow: hidden;
      margin: 0 auto;
      color: #fff;
      padding: 100px 5%;

      @media (max-width: $medium) {
        margin: 0 20px;
      }

      &.active {
        .#{$className}__card-content {
          max-height: 5000px;
          transition-delay: 1s;
          transition: max-height 1s ease-in-out;
        }
      }

      &.error {
        .#{$className}__card-content {
          text-align: center;
        }
      }

      &::before {
        background: url('~@/assets/border-top-engramless.png') center no-repeat;
        top: 0;
      }

      &::after {
        background: url('~@/assets/border-bottom.png') center no-repeat;
        bottom: 0;
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100px;
        background-size: cover;

        @media (max-width: $medium) {
          background-size: contain;
        }
      }

      &-button {
        font-size: 0;
        width: 100px;
        height: 100px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: transparent;
        border: 0;
        cursor: pointer;
        outline: none;

        &-inner {
          .#{$className}__engram {
            width: 100px;
            height: 100px;
            background-size: 100px;

            @media (max-width: $small) {
              background-size: 50px;
            }

            &-bottom,
            &-left,
            &-right {
              background-size: 100px;

              @media (max-width: $small) {
                background-size: 50px;
              }
            }
          }
        }
      }

      &-close {
        position: absolute;
        cursor: pointer;
        background: url('~@/assets/close.png') center no-repeat;
        background-size: contain;
        width: 30px;
        height: 30px;
        top: 15px;
        right: 0;
        transition: .2s;

        &:hover {
          opacity: .6;
        }
      }
    }

    &__card-title {
      font-size: 22px;

      @media (max-width: $small) {
        font-size: 18px;
      }
    }

    &__card-content {
      max-height: 0;
      overflow: hidden;
      text-align: justify;
    }

    &__card-subtitle,
    &__card-text {
      font-size: 16px;
      line-height: 24px;

      @media (max-width: $small) {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
</style>

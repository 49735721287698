import Vue from 'vue'
import Generator from './components/RandomLoreGenerator.vue'
import vueResource from 'vue-resource'
import Api from './api/api.js'

Vue.config.productionTip = false
Vue.config.devtools = true
Vue.use(vueResource)

var app = new Vue({
  components: { Generator },
  render: (h) => {
    return h(Generator)
  }
})

var $api = new Api(app.$http)

Vue.mixin({
  created: function () {
    this.$api = $api
  }
})

document.addEventListener('DOMContentLoaded', function () {
  app.$mount('#app')
})
